import { useEffect, useState } from "react";
import { default as config } from '../../config/config.json';

function Weather(){

    const [weatherState, setWeather] = useState('')

    useEffect(()=>{
        if(!config?.weatherLocation) return;
        async function getWeather(){
            try {
                const fetched = await fetch('https://weather.streamsave.xyz/get?city=congleton');
                const json = await fetched.json();
                setWeather(Math.round(json.current.feels_like));
            } catch(err){
                console.log(err);
                return;
            }
        }

        getWeather();
        /* Polls every 5 minutes */
        setInterval(()=>{ getWeather() }, 1000 * 60 * 5);
    })

    if(!config?.weatherLocation) return;
    return(
        <div className="tablet:hidden small:hidden laptop:block fixed top-8 left-10 font-semibold text-2xl text-white select-none">
            {weatherState}°C
        </div>
    );
}

export default Weather;