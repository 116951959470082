class CurrentDate {
    constructor(date){
        Object.assign(this, { date,  });
    }

    _getDaysList(){
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    }

    _getMonthsList(){
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    }

    _getMonthsSmallList(){
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    }

    getTodayName(){
        return this._getDaysList()[this.date.getDay()];
    }

    getMonthName(){
        return this._getMonthsList()[this.date.getMonth()]
    }

    getDayName(){
        const nth = function(d) {
            if (d > 3 && d < 21) return 'th';
            switch (d % 10) {
                case 1:  return "st";
                case 2:  return "nd";
                case 3:  return "rd";
                default: return "th";
            }
        }

        return `${this.date.getDate() + nth(this.date.getDate())}`
    }

    getFullYear(){
        return this.date.getFullYear()
    }

    getCurrentDate(){
        return `${this.getTodayName()} ${this.getDayName()} ${this.getMonthName()} ${this.getFullYear()}`
    }
}

export default CurrentDate;