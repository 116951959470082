import { useEffect, useState } from "react";
import { default as config } from '../../config/config.json';
import CurrentDate from "../util/currentDate";

function Time(){
    const [time, setTime] = useState(new Date().toLocaleTimeString());
    const [date, setDate] = useState(new CurrentDate(new Date()).getCurrentDate())

    useEffect(()=>{ 
        if(!config?.showTime) return;
        setInterval(()=>{ 
            setTime(new Date().toLocaleTimeString());  
            if(new CurrentDate(new Date()).getCurrentDate() === date) return;
            setDate(new CurrentDate(new Date()).getCurrentDate());
        }, 1000) 
    }, []);

    if(!config?.showTime) return;
    return (
        <div className="tablet:hidden small:hidden laptop:block fixed top-8 right-10 font-semibold text-2xl text-white select-none">
            <div className="text-right">{time}</div>
            <div>{date}</div>
        </div>
    );
}

export default Time;
