import Time from "./partials/time";
import Items from "./partials/items";
import Weather from "./partials/weather";

document.body.classList.add('bg-gradient-to-b', 'from-purple-900', 'to-blue-900');

function App(){
    return (
        <>
            <Weather/>
            <Time/>
            <Items/>
        </>
    )
}

export default App;