import { default as data } from '../../config/dash.json';
import { default as config } from '../../config/config.json';
import { useEffect } from 'react';

function Items(){

    useEffect(()=>{
        if(config.backgroundImage){ 
            document.body.style.backgroundImage = `url('${config.backgroundImage}')`;
            document.getElementById('itemContainer').style.background = 'transparent'; 
        }
    }, [])

    const payload = Object.entries(data).map(([k, v]) => { 
        return <a href={v.href || config.defaultDomain } key={k} target="_blank" title={v?.desc || k} rel="noreferrer">
            <li className="rounded-full drop-shadow-md mx-auto aligm-center">
                <figure className="ml-6 mr-6 mt-3 mb-3">
                    <img src={v.image} className="h-20 w-20 transition-all hover:scale-95 rounded-xl" alt={v.title} />
                    <figcaption className="text-center">{v.title || "json needs title"}</figcaption>
                </figure>
            </li>
        </a>
    });

    return(
        <div id="itemContainer" className="flex select-none h-screen w-screen text-white">
            <div className="m-auto mt-auto desktop:w-5/6 laptop:w-4/6 tablet:w-full">
                <ul className="flex flex-1 flex-wrap justify-center justify-items-center item-center">
                    {payload}
                </ul>
            </div>
        </div>
    );
}

export default Items;